import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'core/components/Avatar.js';
import Icon from 'core/components/Icon.js';
import HeaderButtonGroup from 'professional/components/HeaderButtonGroup.js';
import Tabs from 'professional/components/Tabs.js';
import {TELEHEALTH_ONLY, TELEHEALTH_HYBRID} from 'core/telehealth.js';
import Chips from 'core/components/Chips.js';
import {TELEHEALTH} from 'core/constants.js';

export default class ProfileHeader extends React.Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        avatar: PropTypes.string,
        bookingIntegration: PropTypes.shape({
            bookingId: PropTypes.bookingId,
            id: PropTypes.number,
            name: PropTypes.name,
            url: PropTypes.baseUrl,
        }),
        bookingUrl: PropTypes.string,
        claimUrl: PropTypes.string,
        client: PropTypes.string,
        displayName: PropTypes.string.isRequired,
        displayedTabs: PropTypes.array.isRequired,
        genderName: PropTypes.string.isRequired,
        hasSkin: PropTypes.bool,
        initials: PropTypes.string,
        isEnhanced: PropTypes.bool,
        isReferrals: PropTypes.bool,
        isRetired: PropTypes.bool.isRequired,
        isStaff: PropTypes.bool.isRequired,
        languages: PropTypes.arrayOf(PropTypes.string).isRequired,
        offersTelehealth: PropTypes.bool,
        pdfUrl: PropTypes.string.isRequired,
        practices: PropTypes.arrayOf(PropTypes.object),
        profileId: PropTypes.number.isRequired,
        qualificationsTitle: PropTypes.string,
        ratings: PropTypes.shape({
            numberOfRatings: PropTypes.number.isRequired,
            rating: PropTypes.string.isRequired,
        }),
        scheduleUrl: PropTypes.string,
        showBookAppointmentModal: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        showReferralModal: PropTypes.func.isRequired,
        specialistNowDomain: PropTypes.string,
        specialties: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                displayName: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
        totalContributions: PropTypes.number,
        userType: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.headerRef = React.createRef();
    }

    get offersTelehealth() {
        const telehealthPractices = this.props.practices.filter(
            (p) =>
                p.offersTelehealth === TELEHEALTH_ONLY ||
                p.offersTelehealth === TELEHEALTH_HYBRID,
        );
        return this.props.offersTelehealth || telehealthPractices?.length > 0;
    }

    renderSpecialties() {
        const {specialties} = this.props;

        if (!specialties.length) {
            return <dd>{'Healthshare professional'}</dd>;
        }

        return (
            <dd title="Specialties">
                {specialties
                    .map((specialty, idx) => {
                        const {displayName} = specialty;

                        return (
                            <React.Fragment key={idx}>
                                {displayName}
                            </React.Fragment>
                        );
                    })
                    .reduce((previous, current) => [previous, ', ', current])}
            </dd>
        );
    }

    renderLanguages() {
        const {languages} = this.props;
        return languages
            .map((lang, idx) => (
                <React.Fragment key={idx}>{lang}</React.Fragment>
            ))
            .reduce(
                (previous, current, idx) => {
                    if (idx === languages.length - 1) {
                        return [previous, ' and ', current];
                    } else {
                        return [previous, ', ', current];
                    }
                },
                ['Speaks English'],
            );
    }

    renderInfo() {
        const {genderName, languages, specialties, isRetired} = this.props;
        if (
            specialties ||
            (genderName && genderName !== 'Unknown') ||
            !!languages.length
        ) {
            return (
                <dl className="icon-list">
                    {specialties && (
                        <div>
                            <dt title="Specialties">
                                <Icon name="specialties" />
                                {'Specialties: '}
                            </dt>
                            {this.renderSpecialties()}
                        </div>
                    )}
                    {genderName &&
                        genderName !== 'Unknown' &&
                        genderName !== 'Other' && (
                            <div>
                                <dt title="Gender">
                                    <Icon name={`gender${genderName}`} />
                                </dt>
                                <dd title="Gender">{genderName}</dd>
                            </div>
                        )}
                    {!!languages.length && (
                        <div>
                            <dt title="Languages">
                                <Icon name="language" />
                            </dt>
                            <dd title="Languages">{this.renderLanguages()}</dd>
                        </div>
                    )}
                    {this.offersTelehealth && (
                        <Chips
                            customClass="telehealth"
                            labels={[
                                {
                                    customClass: 'telehealth',
                                    name: TELEHEALTH,
                                    tooltip:
                                        'This practitioner offers phone and/or video consultations.',
                                },
                            ]}
                        />
                    )}
                    {isRetired && (
                        <span className="chip neutral">Retired</span>
                    )}
                </dl>
            );
        }
        return null;
    }

    render() {
        const {
            activeTab,
            avatar,
            client,
            displayName,
            displayedTabs,
            initials,
            isEnhanced,
            isRetired,
            isStaff,
            pdfUrl,
            practices,
            profileId,
            qualificationsTitle,
            totalContributions,
            ratings,
        } = this.props;

        return (
            <header className="card-profile" ref={this.headerRef}>
                <div>
                    <div>
                        <Avatar
                            avatar={avatar}
                            displayName={displayName}
                            initials={initials}
                            profileId={profileId}
                        />
                        <h1>
                            {displayName}
                            {isStaff && isEnhanced && (
                                <Icon name="checkmark" />
                            )}
                        </h1>
                        {qualificationsTitle && (
                            <h2 className="qualifications">
                                {qualificationsTitle}
                            </h2>
                        )}
                        {this.renderInfo()}
                    </div>
                    {!isRetired && <HeaderButtonGroup {...this.props} />}
                </div>
                <Tabs
                    activeTab={activeTab}
                    client={client}
                    displayName={displayName}
                    parentRef={this.headerRef}
                    pdfUrl={pdfUrl}
                    practices={practices}
                    ratings={ratings}
                    tabs={displayedTabs}
                    totalContributions={totalContributions}
                />
            </header>
        );
    }
}
