import PropTypes from 'prop-types';
import React from 'react';

import analytics from 'analytics.js';
import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';

export default class NibInfo extends React.Component {
    static propTypes = {
        healthFund: PropTypes.number,
    };

    componentDidMount() {
        const {healthFund} = this.props;
        if (!window.trackedEligibilityImpression) {
            analytics.track('healthFundEligibilityCheckerImpression', {
                'health_fund': healthFund,
            });
            window.trackedEligibilityImpression = true;
        }
    }

    @autobind
    trackClick() {
        const {healthFund} = this.props;
        if (!window.trackedEligibilityClick) {
            analytics.track('healthFundEligibilityCheckerClick', {
                'health_fund': healthFund,
            });
            window.trackedEligibilityClick = true;
        }
    }

    render() {
        return (
            <div className="offers-section">
                <h2>{'What does my policy cover?'}</h2>
                <p>
                    {
                        'Check if you’re covered for a procedure and learn how to reduce out-of-pocket-costs.'
                    }
                </p>
                <Link
                    action={this.trackClick}
                    customClass="cta offers tertiary"
                    href={
                        'https://www.nib.com.au/health-information/going-to-hospital?source=healthshare'
                    }
                    isExternalLink={true}
                >
                    <strong>{'Check your cover now'}</strong>
                    <Icon name={'bookmark'} />
                </Link>
            </div>
        );
    }
}
