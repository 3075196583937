import {observer} from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';

import query from 'query.js';

import Ad from 'core/components/Ad.js';
import HicapsBadge from 'core/components/HicapsBadge.js';
import Icon from 'core/components/Icon.js';
import LinkWithProperties from 'core/components/LinkWithProperties.js';
import List from 'core/components/List.js';
import Tooltip from 'core/components/Tooltip.js';
import PracticesList from 'professional/components/PracticesList.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';

export default
@observer
class ProfileSidebar extends React.Component {
    static contextType = ProfileStoreContext;

    static propTypes = {
        adData: PropTypes.shape({
            targeting: PropTypes.object.isRequired,
        }),
        client: PropTypes.string,
        isReferrals: PropTypes.bool.isRequired,
        isRetired: PropTypes.bool.isRequired,
        practices: PropTypes.arrayOf(PropTypes.object).isRequired,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string,
        showModal: PropTypes.func.isRequired,
        staticUrl: PropTypes.string.isRequired,
    };

    renderHicaps() {
        const {practices} = this.props;
        const offersHicaps = practices.find(
            (practice) => practice.offersHicaps,
        );
        return offersHicaps ? <HicapsBadge /> : null;
    }

    formatHospital(hospital) {
        const {healthFund} = this.context.rootStore.healthFundStore;
        const {id, displayName} = hospital;
        let url = hospital.url;

        return (
            <li key={id}>
                <LinkWithProperties
                    href={url}
                    searchParams={
                        healthFund?.id ? {health_fund: healthFund.id} : null
                    }
                >
                    <Icon name="hospital" />
                    {displayName}
                </LinkWithProperties>
            </li>
        );
    }

    renderHospitalAffiliations() {
        const {hospitals} = this.context;
        if (!hospitals?.length) {
            return null;
        }
        return (
            <List customClass="related" title="Hospital affiliations">
                {hospitals.map((hospital) => {
                    if (hospital.hasOwnProperty('hfr')) {
                        const {hfr, hospitals} = hospital;
                        return (
                            <>
                                <div className="hfr-header">
                                    <span>{`${hfr.displayName}s`}</span>
                                    <Tooltip
                                        content={hfr.tooltip}
                                        defaultPosition="top"
                                        timeOut={500}
                                    >
                                        <Icon name="info" />
                                    </Tooltip>
                                </div>
                                {hospitals.map((hospital) =>
                                    this.formatHospital(hospital),
                                )}
                            </>
                        );
                    } else {
                        return this.formatHospital(hospital);
                    }
                })}
            </List>
        );
    }

    render() {
        const {
            adData,
            client,
            isReferrals,
            isRetired,
            pdfUrl,
            practices,
            profileId,
            profileName,
            showModal,
            staticUrl,
            selectedFund,
        } = this.props;

        return (
            <section className="tray">
                {this.renderHicaps()}
                <PracticesList
                    client={client}
                    isRetired={isRetired}
                    isReferrals={isReferrals}
                    practices={practices}
                    profileId={profileId}
                    profileName={profileName}
                    selectedFund={selectedFund}
                    showModal={showModal}
                    staticUrl={staticUrl}
                />

                {this.renderHospitalAffiliations()}
                {adData && <Ad {...adData} adUnit="sidebar" slotNumber={2} />}
            </section>
        );
    }
}
