import {observer} from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import CollapsibleText from 'core/components/CollapsibleText.js';
import Link from 'core/components/Link.js';
import ImageGallery from 'core/components/ImageGallery.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import {feesQuestion} from 'professional/components/Fees.js';
import FundSelection from 'professional/components/FundSelection.js';
import Video from 'professional/components/Video.js';

const EM_DASH_UTC = ' \u2014 ';

export default
@observer
class Overview extends React.Component {
    static contextType = ProfileStoreContext;

    static propTypes = {
        awards: PropTypes.string,
        bio: PropTypes.string,
        claimUrl: PropTypes.string,
        isSpecialist: PropTypes.bool,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string.isRequired,
        qualifications: PropTypes.string,
        registrationNumbers: PropTypes.string,
        specialInterests: PropTypes.string,
        tagline: PropTypes.string,
        videos: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string.isRequired,
                thumbnail: PropTypes.string,
            }),
        ).isRequired,
    };

    @autobind
    onClickImage(index) {
        this.props.renderGallery(index);
    }

    renderProfileSection(sectionHeader, htmlText, legacy = true) {
        let customClassName = legacy ? 'legacy' : undefined;
        if (sectionHeader === 'Qualifications') {
            if (customClassName) {
                customClassName += ' qualifications';
            } else {
                customClassName = 'qualifications';
            }
        }
        return (
            <div className={customClassName}>
                <h2>{sectionHeader}</h2>
                <div dangerouslySetInnerHTML={{__html: htmlText}} />
            </div>
        );
    }

    renderFeesSectionHFProvided() {
        const {healthFund} = this.context.rootStore.healthFundStore;
        return (
            <>
                <h2>
                    {'Fees'}
                    <em>
                        {EM_DASH_UTC}
                        {`Provided by ${healthFund?.name}`}
                    </em>
                </h2>
                <div className="button-group">
                    <Link customClass="cta secondary" href="#fees">
                        {'See fees information'}
                    </Link>
                </div>
            </>
        );
    }

    renderFeesSectionNoHF() {
        const {isSpecialist, profileId} = this.props;
        const {updateSelectedFund} = this.context;
        const {
            healthFunds,
            healthFund,
        } = this.context.rootStore.healthFundStore;
        const {isReferrals} = this.context.rootStore.paramStore;

        const formLabel = (
            <>
                {'Fees'}
                <em>
                    {EM_DASH_UTC}
                    {feesQuestion(isReferrals)}
                </em>
            </>
        );

        return (
            <FundSelection
                formLabel={formLabel}
                healthFunds={healthFunds}
                isReferrals={isReferrals}
                profileId={profileId}
                selectedFundId={healthFund?.id}
                showNoInsurance={isSpecialist}
                updateSelectedFund={updateSelectedFund}
            />
        );
    }

    renderFeesQuestion() {
        const {healthFund} = this.context.rootStore.healthFundStore;

        return (
            <section className="fees-check">
                {healthFund?.id
                    ? this.renderFeesSectionHFProvided()
                    : this.renderFeesSectionNoHF()}
            </section>
        );
    }

    renderImages() {
        const {images} = this.props;
        if (!images?.length) {
            return null;
        }
        return (
            <>
                <h2>{'Images'}</h2>
                <ImageGallery images={images} onClick={this.onClickImage} />
            </>
        );
    }

    renderVideos() {
        const {profileId, videos} = this.props;
        if (!videos || !videos.length) {
            return null;
        }
        return (
            <>
                <h2>{'Relevant videos'}</h2>
                {videos.map((video) =>
                    video.link ? (
                        <Video
                            key={video.key}
                            profileId={profileId}
                            video={video}
                        />
                    ) : null,
                )}
            </>
        );
    }

    renderMetadata() {
        const {awards, qualifications, registrationNumbers} = this.props;
        return (
            <section className="metadata">
                {this.renderImages()}
                {this.renderVideos()}
                {qualifications &&
                    this.renderProfileSection(
                        'Qualifications',
                        qualifications,
                    )}
                {registrationNumbers &&
                    this.renderProfileSection(
                        'Registration numbers and professional memberships',
                        registrationNumbers,
                    )}
                {awards &&
                    this.renderProfileSection(
                        'Awards and publications',
                        awards,
                    )}
            </section>
        );
    }

    render() {
        const {
            bio,
            claimUrl,
            profileName,
            specialInterests,
            tagline,
        } = this.props;
        const {healthFunds} = this.context.rootStore.healthFundStore;

        return (
            <>
                <h1>
                    {`About ${profileName}`}
                    {claimUrl && (
                        <em>
                            {EM_DASH_UTC}
                            <Link href={claimUrl}>
                                {'is this you? Claim your profile'}
                            </Link>
                        </em>
                    )}
                </h1>
                {tagline && (
                    <p
                        className="notification neutral"
                        dangerouslySetInnerHTML={{__html: tagline}}
                    />
                )}
                <article className="bio">
                    <CollapsibleText
                        expandText="Read more"
                        maxWords={30}
                        renderHtml={true}
                        text={bio}
                    />
                    {specialInterests &&
                        this.renderProfileSection(
                            'Special interests',
                            specialInterests,
                            false,
                        )}
                </article>
                {healthFunds.length > 0 && this.renderFeesQuestion()}
                {this.renderMetadata()}
            </>
        );
    }
}
