import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'core/components/Icon.js';
import Tooltip from 'core/components/Tooltip.js';

export default class PractitionerFeeComments extends React.Component {
    static propTypes = {
        comments: PropTypes.string.isRequired,
        profileName: PropTypes.string.isRequired,
        titleTooltip: PropTypes.string.isRequired,
    };

    titleTooltipContent() {
        return <p>{this.props.titleTooltip}</p>;
    }

    render() {
        const {comments, profileName, titleTooltip} = this.props;
        return (
            <section className="tagline">
                <h2>{`Fee Information for ${profileName}`}</h2>
                {titleTooltip && (
                    <Tooltip
                        content={this.titleTooltipContent()}
                        defaultPosition="top"
                        timeOut={500}
                    >
                        <Icon name="info" />
                    </Tooltip>
                )}
                <p>{comments}</p>
            </section>
        );
    }
}
