import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';
import React from 'react';

import PromotedProfiles from 'promotions/components/PromotedProfiles.js';

export default class PromotedProfilesBanner extends React.PureComponent {
    static propTypes = {
        gapSchemeResults: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                avatar: PropTypes.string,
                id: PropTypes.number,
                displayName: PropTypes.string,
                profileUrl: PropTypes.string,
                specialties: PropTypes.arrayOf(PropTypes.object),
            }),
        ),
        gapSchemeResultsCount: PropTypes.number,
        globalFund: PropTypes.shape({
            'health_fund_id': PropTypes.number,
            'gap_scheme_id': PropTypes.number,
        }),
        isMobile: PropTypes.bool,
        isMobileOrTablet: PropTypes.bool,
        localityId: PropTypes.number,
        profileId: PropTypes.number.isRequired,
        specialtyId: PropTypes.number,
    };

    orderGapSchemeResults(gapSchemeResults) {
        /* randomly sorts results, with precedence to enhanced profiles
        followed by profiles with avatars */

        const randomResults = gapSchemeResults.map((result) => {
            return {...result, randomIndex: Math.random()};
        });
        randomResults.sort((a, b) => a.randomIndex - b.randomIndex);

        randomResults.sort(
            (a, b) =>
                a.avatar.includes('empty_avatar') -
                b.avatar.includes('empty_avatar'),
        );

        randomResults.sort((a, b) => b.isEnhanced - a.isEnhanced);

        return randomResults;
    }

    render() {
        const {
            gapSchemeResults,
            gapSchemeResultsCount,
            globalFund,
            isMobile,
            isMobileOrTablet,
            localityId,
            specialtyId,
        } = this.props;
        if (!globalFund) {
            return null;
        }
        const {
            'health_fund_id': fundId,
            'gap_scheme_id': gapSchemeId,
        } = globalFund;
        const profiles = this.orderGapSchemeResults(gapSchemeResults);
        const length = gapSchemeResults.length;
        if (length > 0) {
            let className = 'promoted-scheme';
            if (length < 3) {
                className += ' reduced';
            }
            return (
                <section className={className}>
                    <p>
                        Bupa members with appropriate Hospital Cover can access{' '}
                        <strong>100% no gap</strong> hospital and treatment
                        options with selected medical specialists:
                    </p>
                    <PromotedProfiles
                        gapSchemeId={gapSchemeId}
                        gapSchemeResultsCount={gapSchemeResultsCount}
                        healthFundId={fundId}
                        isMobile={isMobile}
                        isMobileOrTablet={isMobileOrTablet}
                        localityId={localityId}
                        profiles={profiles}
                        specialtyId={specialtyId}
                        version={1}
                    />
                </section>
            );
        }
        return null;
    }
}
