import React from 'react';
import PropTypes from 'prop-types';

import {getCopyData} from 'core/referrals.js';
import LocationInfo from 'core/components/LocationInfo.js';
import {TELEHEALTH_ONLY} from 'core/telehealth.js';
import ContactInfo from 'professional/components/ContactInfo.js';
import {
    HSReferralButton,
    MDWriteReferralButton,
    BPSecureMessageButton,
    BPWriteReferralButton,
} from 'professional/components/ReferralButtons.js';
import PracticeMoreInfo from 'professional/components/PracticeMoreInfo.js';

const HBF_ID = 32;
export default class PracticesList extends React.Component {
    static propTypes = {
        client: PropTypes.string,
        practices: PropTypes.arrayOf(
            PropTypes.shape({
                address: PropTypes.string.isRequired,
                bpData: PropTypes.object,
                clipboardData: PropTypes.string,
                extraInfo: PropTypes.shape({
                    comment: PropTypes.string,
                    openHours: PropTypes.array,
                    paymentOptions: PropTypes.array,
                    serviceOptions: PropTypes.string,
                }),
                fax: PropTypes.shape({
                    description: PropTypes.string,
                    number: PropTypes.string.isRequired,
                }),
                fhirData: PropTypes.object,
                id: PropTypes.number,
                isReferrals: PropTypes.bool,
                name: PropTypes.string.isRequired,
                mdData: PropTypes.object,
                openIndicator: PropTypes.string,
                offersTelehealth: PropTypes.bool,
                phones: PropTypes.arrayOf(
                    PropTypes.shape({
                        description: PropTypes.string,
                        number: PropTypes.string.isRequired,
                    }),
                ),
                practiceUrl: PropTypes.string,
                website: PropTypes.string,
            }),
        ).isRequired,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string,
        showModal: PropTypes.func.isRequired,
        staticUrl: PropTypes.string.isRequired,
    };

    renderReferralButtons(practice, isReferrals, index) {
        const {bpData, fhirData, id, mdData, offersTelehealth} = practice;
        const {profileId, profileName} = this.props;
        const showHSReferralButton =
            !bpData && !fhirData && !mdData && isReferrals;
        if (bpData || fhirData || mdData || showHSReferralButton) {
            const getCopyDataWithProfile = () =>
                getCopyData(profileName, practice);
            return (
                <div className="button-group">
                    {showHSReferralButton && (
                        <HSReferralButton
                            clickTrackingData={{
                                eventAction: 'click',
                                eventCategory: 'copy referral',
                                eventLabel: `sidebar - position ${index + 1}`,
                            }}
                            eventData={{
                                eventName: 'copyPracticeDetails',
                                data: {
                                    'practice_position': id,
                                },
                            }}
                            getCopyData={getCopyDataWithProfile}
                            impressionTrackingData={{
                                action: 'impression',
                                category: 'copy referral',
                                label: `sidebar - position ${index + 1}`,
                            }}
                        />
                    )}
                    {bpData && (
                        <BPWriteReferralButton
                            bpData={bpData}
                            clickTrackingData={{
                                eventAction: 'click',
                                eventCategory: 'write referral',
                                eventLabel: `sidebar - position ${index + 1}`,
                            }}
                            eventData={{
                                practicePositionId: id,
                                practitionerId: profileId,
                            }}
                            impressionTrackingData={{
                                action: 'impression',
                                category: 'write referral',
                                label: `sidebar - position ${index + 1}`,
                            }}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {mdData && (
                        <MDWriteReferralButton
                            clickTrackingData={{
                                eventAction: 'click',
                                eventCategory: 'write referral',
                                eventLabel: `sidebar - position ${index + 1}`,
                            }}
                            eventData={{
                                practicePositionId: id,
                                practitionerId: profileId,
                            }}
                            impressionTrackingData={{
                                action: 'impression',
                                category: 'write referral',
                                label: `sidebar - position ${index + 1}`,
                            }}
                            mdData={mdData}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {fhirData && (
                        <BPSecureMessageButton
                            clickTrackingData={{
                                eventAction: 'click',
                                eventCategory: 'secure message',
                                eventLabel: `sidebar - position ${index + 1}`,
                            }}
                            eventData={{
                                practicePositionId: id,
                                practitionerId: profileId,
                            }}
                            impressionTrackingData={{
                                action: 'impression',
                                category: 'secure message',
                                label: `sidebar - position ${index + 1}`,
                            }}
                            fhirData={fhirData}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                </div>
            );
        }
        return null;
    }

    renderHealthFundHeading(healthFundRelationship) {
        if (healthFundRelationship?.length > 0) {
            const {selectedFund} = this.props;

            if (selectedFund) {
                const {name, avatar, id, colorBrand} = selectedFund;
                const isHBF = id === HBF_ID;
                const isRender = healthFundRelationship?.some(
                    (hfr) => hfr.healthFundId === id,
                );

                if (isRender) {
                    return (
                        <header
                            className={`hf-header`}
                            style={
                                colorBrand
                                    ? {color: `#${colorBrand}`}
                                    : undefined
                            }
                        >
                            <img alt="hfIcon" src={avatar} />
                            <span>{`${name}${
                                isHBF ? ' Member Plus agreement' : ''
                            }`}</span>
                        </header>
                    );
                }
            }
        }
        return null;
    }

    render() {
        const {
            client,
            isRetired,
            isReferrals,
            profileId,
            showModal,
            staticUrl,
        } = this.props;
        let {practices} = this.props;

        if (!practices.length) {
            return null;
        }

        return (
            <>
                {practices.length > 0 && (
                    <h2 className="practice-locations">
                        {isRetired
                            ? 'Previous practice locations'
                            : `${practices.length} Practice location${
                                  practices.length > 1 ? 's' : ''
                              }`}
                    </h2>
                )}
                <ul className="practices">
                    {practices.map((practice, index) => {
                        const {
                            address,
                            extraInfo: {
                                comment,
                                openHours,
                                paymentOptions,
                                serviceOptions,
                            },
                            fax,
                            healthFundRelationship,
                            id,
                            name,
                            offersHicaps,
                            offersTelehealth,
                            openIndicator,
                            phones,
                            practiceUrl,
                            website,
                        } = practice;

                        return (
                            <li
                                className="location practitioner-location"
                                key={`prac-info-${id}`}
                            >
                                {this.renderHealthFundHeading(
                                    healthFundRelationship,
                                )}
                                <section className="hf-content">
                                    <LocationInfo
                                        address={address}
                                        client={client}
                                        practiceName={name}
                                        practiceUrl={practiceUrl}
                                        staticUrl={staticUrl}
                                        telehealthOnly={false}
                                    />
                                    {this.renderReferralButtons(
                                        practice,
                                        isReferrals,
                                        index,
                                    )}
                                    <div>
                                        {!isRetired && (
                                            <ContactInfo
                                                client={client}
                                                fax={fax}
                                                offersHicaps={offersHicaps}
                                                offersTelehealth={
                                                    offersTelehealth
                                                }
                                                phones={phones}
                                                practice={practice}
                                                practiceId={id}
                                                practiceName={name}
                                                practiceOrder={index}
                                                profileId={profileId}
                                                showModal={showModal}
                                                website={website}
                                            />
                                        )}
                                        <PracticeMoreInfo
                                            client={client}
                                            comment={comment}
                                            customClass="extra-info"
                                            displayText="Open hours and more"
                                            openHours={openHours}
                                            openIndicator={openIndicator}
                                            paymentOptions={paymentOptions}
                                            serviceOptions={serviceOptions}
                                        />
                                    </div>
                                </section>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    }
}
