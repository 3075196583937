import {observer} from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import {onReactionError} from 'mobx';

import {getCopyData} from 'core/referrals.js';
import autobind from 'common/decorators/autobind.js';
import events from 'events.js';
import {getScrollTarget} from 'professional/scroll_options.js';
import hotjar from 'hotjar.js';
import http from 'http.js';
import query from 'query.js';
import {captureException} from 'capture_sentry_exception.js';

import {PAGE_TYPE_PRACTITIONER, PROFILE_CONTACT_TYPE} from 'core/constants.js';
import Ad from 'core/components/Ad.js';
import ContactModal from 'core/components/ContactModal.js';

import FocusManager from 'core/components/FocusManager.js';
import BookAppointmentModal from 'core/components/BookAppointmentModal.js';
import Modal from 'core/components/Modal.js';
import ImageGalleryModal from 'core/components/ImageGalleryModal.js';
import ReferralModal from 'core/components/ReferralModal.js';
import RelatedInfo from 'core/components/RelatedInfo.js';
import Tools from 'core/components/Tools.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import Overview from 'professional/components/Overview.js';
import ProfileHeader from 'professional/components/ProfileHeader.js';
import ProfileSidebar from 'professional/components/ProfileSidebar.js';
import Fees from 'professional/components/Fees.js';
import QA from 'professional/components/QA.js';
import Ratings from 'professional/components/Ratings.js';
import PromotionsApp from 'promotions/components/PromotionsApp.js';

export default
@observer
class ProfileApp extends React.Component {
    static contextType = ProfileStoreContext;

    static propTypes = {
        adData: PropTypes.shape({
            targeting: PropTypes.object.isRequired,
        }),
        avatar: PropTypes.string,
        awards: PropTypes.string,
        bio: PropTypes.string,
        bookingIntegration: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.name,
            url: PropTypes.baseUrl,
            bookingId: PropTypes.bookingId,
        }),
        bookingUrl: PropTypes.string,
        claimUrl: PropTypes.string,
        genderName: PropTypes.string.isRequired,
        globalPromotedGapSchemes: PropTypes.arrayOf(
            PropTypes.shape({
                gap_scheme_id: PropTypes.number, // eslint-disable-line camelcase
                health_fund_id: PropTypes.number, // eslint-disable-line camelcase
            }),
        ),
        initials: PropTypes.string,
        interests: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                specialty: PropTypes.number.isRequired,
            }),
        ).isRequired,
        isEnhanced: PropTypes.bool.isRequired,
        isGP: PropTypes.bool.isRequired,
        isRetired: PropTypes.bool.isRequired,
        isSpecialist: PropTypes.bool.isRequired,
        languages: PropTypes.arrayOf(PropTypes.string).isRequired,
        offersTelehealth: PropTypes.bool,
        pdfUrl: PropTypes.string.isRequired,
        practices: PropTypes.arrayOf(
            PropTypes.shape({
                address: PropTypes.string,
                id: PropTypes.number,
                extraInfo: PropTypes.object,
                fax: PropTypes.shape({
                    number: PropTypes.string.isRequired,
                }),
                localityName: PropTypes.string,
                localityState: PropTypes.string,
                name: PropTypes.string,
                phones: PropTypes.arrayOf(
                    PropTypes.shape({
                        number: PropTypes.string.isRequired,
                    }),
                ),
                practiceUrl: PropTypes.string,
                services: PropTypes.array,
            }),
        ).isRequired,
        profileFeeComments: PropTypes.string,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string.isRequired,
        profileUrl: PropTypes.string.isRequired,
        promotedGapSchemes: PropTypes.arrayOf(
            PropTypes.shape({
                gapSchemeId: PropTypes.number,
                healthFundId: PropTypes.number,
            }),
        ),
        promotions: PropTypes.arrayOf(
            PropTypes.shape({
                healthFunds: PropTypes.arrayOf(PropTypes.number).isRequired,
                specialties: PropTypes.arrayOf(PropTypes.number).isRequired,
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                order: PropTypes.number.isRequired,
            }),
        ),
        qualifications: PropTypes.string,
        qualificationsTitle: PropTypes.string,
        ratings: PropTypes.shape({
            bedsideManner: PropTypes.string.isRequired,
            conditionUnderstanding: PropTypes.string.isRequired,
            numberOfRatings: PropTypes.number.isRequired,
            rating: PropTypes.string.isRequired,
            treatmentCommunication: PropTypes.string.isRequired,
            wouldRecommend: PropTypes.number.isRequired,
        }),
        registrationNumbers: PropTypes.string,
        relatedInfo: PropTypes.object,
        scheduleUrl: PropTypes.string,
        showSpecialistNowBanner: PropTypes.bool.isRequired,
        skin: PropTypes.string,
        specialInterests: PropTypes.string,
        specialistNowDomain: PropTypes.string.isRequired,
        specialties: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string,
                id: PropTypes.number,
                isSpecialist: PropTypes.bool,
                name: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
        specialtyRegionTriggers: PropTypes.array.isRequired,
        staticUrl: PropTypes.string.isRequired,
        tagline: PropTypes.string,
        totalAgrees: PropTypes.number,
        totalContributions: PropTypes.number,
        totalThanks: PropTypes.number,
        userId: PropTypes.number.isRequired,
        userType: PropTypes.string,
        videos: PropTypes.array.isRequired,
        images: PropTypes.array,
    };

    constructor(props) {
        super(props);

        onReactionError(captureException);

        this.state = {
            answers: [],
            currentBookAppointmentPractice: null,
            isMobile: false,
            isMobileOrTablet: false,
            modalPhoneNumber: undefined,
            nextAnswerUrl: '',
            scrollToSection: undefined,
            showBookAppointmentModal: false,
            showModal: false,
            showReferralModal: false,
            selectedPractice: null,
            showGalleryModal: false,
            selectedImage: null,
        };

        // TODO: remove after rollout is complete
        document.body.classList.remove('integrated-profile');
    }

    componentDidMount() {
        const {
            isRetired,
            ratings,
            relatedInfo,
            totalContributions,
        } = this.props;
        this.context.updateStore({
            isIntegratedFeature: false,
            isRetired,
            ratings,
            relatedInfo,
            totalContributions,
        });
        this.loadAnswers();
        this.setUpHotjarTriggers();
        this.setState(this.getInitialState());
        this.listenToHashChanges();
        this.context.setActiveTab();
    }

    componentWillUnmount() {
        events.unlisten(window, ['hashchange', 'popstate']);
    }

    getInitialState() {
        const {isRetired} = this.props;
        const scrollTo = getScrollTarget(query.parse().section);
        const initialState = {};
        if (!isRetired && scrollTo) {
            this.context.replaceHashWith(`#${scrollTo.tab}`);
            initialState.scrollToSection = scrollTo.target;
        }
        return initialState;
    }

    // We want to change active tab based on the current #value
    listenToHashChanges() {
        events.listen(window, ['hashchange', 'popstate'], (event) => {
            if (event.type == 'hashchange') {
                this.context.setActiveTab();
            }
        });
    }

    @autobind
    async loadAnswers() {
        const {totalContributions, userId} = this.props;
        let url;
        if (this.state.nextAnswerUrl) {
            url = this.state.nextAnswerUrl;
        } else {
            url = `/api/accounts/v1/${userId}/thread-replies?limit=10`;
        }
        if (totalContributions) {
            const data = await http.get({url});
            this.setState((state) => ({
                answers: [...state.answers, ...data.results],
                nextAnswerUrl: data.next,
            }));
        }
    }

    @autobind
    closeModal() {
        this.setState({showModal: false});
    }

    @autobind
    showModal(practice, modalPhoneNumber) {
        this.setState({
            showModal: true,
            selectedPractice: practice,
            modalPhoneNumber,
        });
    }

    @autobind
    renderCallNowModal(phoneNumber) {
        const {isEnhanced, practices, profileId, profileName} = this.props;
        const {selectedPractice} = this.state;
        const modalProps = {
            closeModal: this.closeModal,
            contactId: profileId,
            contactName: profileName,
            contactType: PROFILE_CONTACT_TYPE,
            isEnhanced,
            items: practices
                .filter((practice) => practice.phones.length)
                .map((item) => {
                    item.type = PROFILE_CONTACT_TYPE;
                    return item;
                }),
            location: PROFILE_CONTACT_TYPE,
            phoneNumber,
            selectedItem: selectedPractice
                ? {
                      id: selectedPractice,
                      type: PROFILE_CONTACT_TYPE,
                  }
                : null,
        };
        const activeElement = document.activeElement;
        return (
            <Modal
                closeModal={this.closeModal}
                initElement={activeElement}
                parentElement={activeElement.parentElement}
            >
                <ContactModal {...modalProps} />
            </Modal>
        );
    }

    @autobind
    closeReferralModal() {
        this.setState({showReferralModal: false});
    }

    @autobind
    showReferralModal() {
        this.setState({
            showReferralModal: true,
        });
    }

    @autobind
    closeGalleryModal() {
        this.setState({showGalleryModal: false});
    }

    @autobind
    showGalleryModal(index) {
        this.setState({
            showGalleryModal: true,
            selectedImage: index,
        });
    }

    @autobind
    renderReferralModal() {
        const {client, isReferrals} = this.context.rootStore.paramStore;
        const {practices, profileName} = this.props;
        const clientData = practices.map((prac) => {
            return {
                bpData: prac.bpData,
                mdData: prac.mdData,
                practicePositionId: prac.id,
                referralEmail: prac.referralEmail,
            };
        });
        const modalProps = {
            client,
            clientData,
            closeModal: this.closeReferralModal,
            displayName: profileName,
            getCopyData,
            isReferrals,
            locations: practices,
        };
        const activeElement = document.activeElement;
        return (
            <Modal
                closeModal={this.closeReferralModal}
                initElement={activeElement}
                parentElement={activeElement.parentElement}
            >
                <ReferralModal {...modalProps} />
            </Modal>
        );
    }

    // setGalleryIndex
    @autobind
    renderGalleryModal() {
        return (
            <Modal
                closeModal={this.closeGalleryModal}
                initElement={document.activeElement}
                parentElement={document.activeElement.parentElement}
            >
                <ImageGalleryModal
                    closeModal={this.closeGalleryModal}
                    images={this.props.images}
                    selectedIndex={this.state.selectedImage}
                />
            </Modal>
        );
    }

    @autobind
    closeBookAppointmentModal() {
        this.setState({showBookAppointmentModal: false});
    }

    @autobind
    showBookAppointmentModal(practice) {
        this.setState({
            currentBookAppointmentPractice: practice,
            showBookAppointmentModal: true,
        });
    }

    @autobind renderBookAppointmentModal() {
        const {
            avatar,
            initials,
            isReferrals,
            profileId,
            profileName,
            specialties,
        } = this.props;
        const hasSpecialistSpecialty = specialties.find(
            (sp) => sp.isSpecialist,
        );
        const modalProps = {
            avatar,
            closeModal: this.closeBookAppointmentModal,
            displayName: profileName,
            displayScreenerQuestion: !!hasSpecialistSpecialty,
            initials,
            isReferrals,
            practice: this.state.currentBookAppointmentPractice,
            profileId,
        };
        const activeElement = document.activeElement;
        return (
            <Modal
                closeModal={this.closeBookAppointmentModal}
                initElement={activeElement}
                parentElement={activeElement.parentElement}
            >
                <BookAppointmentModal {...modalProps} />
            </Modal>
        );
    }

    setUpHotjarTriggers() {
        const {
            isEnhanced,
            isReferrals,
            isSpecialist,
            specialtyRegionTriggers,
        } = this.props;
        const profileType = isEnhanced ? 'pro' : 'non-pro';
        const specialistType = isSpecialist ? 'specialist' : 'not-specialist';
        // Move this variable's source to state when applied
        const healthFundId = query.parse().health_fund;
        const healthFundTag = healthFundId
            ? `health-fund-${healthFundId}`
            : 'no-health-fund';
        hotjar('trigger', `profile-${profileType}`);
        hotjar('trigger', `${profileType}-profile-${specialistType}`);
        hotjar('trigger', `profile-${profileType}-${healthFundTag}`);
        specialtyRegionTriggers.forEach((trigger) => {
            trigger += isEnhanced ? '_pro' : '';
            trigger += isReferrals ? '_referrals' : '';
            hotjar('trigger', trigger);
        });
    }

    render() {
        let Tab;
        const {
            answers,
            modalPhoneNumber,
            showBookAppointmentModal,
            showModal,
            showReferralModal,
            showGalleryModal,
        } = this.state;
        const {
            tabs: displayedTabs,
            activeProfilePageTab: activeTab,
            isMobile,
            isMobileOrTablet,
            localityId,
            specialtyId,
            rootStore: {
                paramStore: {hasSkin, isReferrals, isStaff, showParam, client},
            },
        } = this.context;
        const {
            adData,
            avatar,
            awards,
            bio,
            bookingIntegration,
            bookingUrl,
            claimUrl,
            genderName,
            initials,
            interests,
            isEnhanced,
            isRetired,
            isSpecialist,
            languages,
            offersTelehealth,
            pdfUrl,
            practices,
            profileId,
            profileName,
            promotions,
            qualifications,
            qualificationsTitle,
            ratings,
            registrationNumbers,
            scheduleUrl,
            showSpecialistNowBanner,
            skin,
            specialInterests,
            specialistNowDomain,
            specialties,
            staticUrl,
            tagline,
            totalAgrees,
            totalContributions,
            totalThanks,
            userType,
            videos,
            images,
        } = this.props;
        const headerProps = {
            activeTab,
            avatar,
            bookingIntegration,
            bookingUrl,
            claimUrl,
            client,
            displayName: profileName,
            displayedTabs,
            genderName,
            hasSkin,
            initials,
            isEnhanced,
            isReferrals,
            isRetired,
            isStaff,
            languages,
            offersTelehealth,
            pdfUrl,
            practices,
            profileId,
            qualificationsTitle,
            scheduleUrl,
            showBookAppointmentModal: this.showBookAppointmentModal,
            showModal: this.showModal,
            showReferralModal: this.showReferralModal,
            specialties,
            specialistNowDomain: showSpecialistNowBanner
                ? specialistNowDomain
                : undefined,
            totalContributions,
            userType,
            gapSchemeResults: this.context.gapSchemeResults,
            gapSchemeResultsCount: this.context.gapSchemeResultsCount,
            isMobile,
            isMobileOrTablet,
            localityId,
            specialtyId,
        };

        headerProps.ratings = ratings
            ? {
                  rating: ratings.rating,
                  numberOfRatings: ratings.numberOfRatings,
              }
            : null;

        const specialtiesNames =
            specialties &&
            specialties.map((specialty) => specialty.displayName);
        if (adData && adData.targeting) {
            adData.targeting.healthFund = query.parse().health_fund
                ? 'True'
                : 'False';
            adData.targeting.specialty = specialtiesNames;
        }

        const sidebarProps = {
            adData,
            client,
            isReferrals,
            isRetired,
            pdfUrl,
            practices,
            profileId,
            profileName,
            staticUrl,
            showModal: this.showModal,
        };

        if (activeTab === 'overview') {
            const overviewProps = {
                awards,
                bio,
                claimUrl,
                profileId,
                profileName,
                qualifications,
                registrationNumbers,
                specialInterests,
                tagline,
                videos,
                images,
                renderGallery: this.showGalleryModal,
            };
            Tab = <Overview {...overviewProps} />;
        } else if (activeTab === 'ratings') {
            Tab = <Ratings {...this.props.ratings} />;
        } else if (activeTab === 'qa') {
            const qaProps = {
                answers,
                avatar,
                initials,
                profileId,
                showMoreCallback: this.loadAnswers,
                shouldShowMoreButton: !!this.state.nextAnswerUrl,
                totalAgrees,
                totalContributions,
                totalThanks,
            };

            Tab = <QA {...qaProps} />;
        } else if (activeTab === 'fees') {
            const {isMobile, isMobileOrTablet, scrollToSection} = this.state;
            const {
                isSpecialist,
                profileFeeComments,
                profileId,
                profileName,
                staticUrl,
            } = this.props;
            const feesProps = {
                client,
                gapSchemeResults: this.context.gapSchemeResults,
                gapSchemeResultsCount: this.context.gapSchemeResultsCount,
                isEnhanced,
                isMobile,
                isMobileOrTablet,
                isReferrals,
                isSpecialist,
                localityId,
                profileFeeComments,
                profileId,
                profileName,
                selectedFeeType: scrollToSection ? 'procedure' : undefined,
                scrollToSection,
                show: showParam,
                skin,
                staticUrl,
                specialtyId,
                title: activeTab,
                updateSelectedFund: this.context.updateSelectedFund,
            };
            Tab = <Fees {...feesProps} />;
        } else if (activeTab === 'related-info') {
            const {relatedInfo} = this.props;
            const data = {
                ...relatedInfo,
                name: profileName,
            };
            Tab = <RelatedInfo {...data} />;
        }

        return (
            <>
                <FocusManager />
                {showModal && this.renderCallNowModal(modalPhoneNumber)}
                {showReferralModal && this.renderReferralModal()}
                {showBookAppointmentModal && this.renderBookAppointmentModal()}
                {showGalleryModal && this.renderGalleryModal()}
                <PromotionsApp
                    closePreviousModal={this.closeModal}
                    isClient={!!client}
                    isEnhanced={isEnhanced}
                    isMobile={isMobile}
                    isMobileOrTablet={isMobileOrTablet}
                    isReferrals={isReferrals}
                    isSpecialist={isSpecialist}
                    practices={practices}
                    promotions={promotions}
                    showPreviousModal={this.showModal}
                    specialInterests={interests}
                    specialties={specialties}
                />
                {adData && <Ad {...adData} adUnit="header" slotNumber={1} />}
                <ProfileHeader {...headerProps} />
                <section
                    aria-labelledby={`${activeTab}-tab`}
                    className="profile metadata"
                    id={`${activeTab}-panel`}
                    role="tabpanel"
                >
                    {Tab && Tab}
                </section>
                <ProfileSidebar {...sidebarProps} />
                <Tools
                    availableCategories={['Address', 'Phone number', 'Other']}
                    displayName={profileName}
                    isClient={!!client}
                    pageType={PAGE_TYPE_PRACTITIONER}
                    pdfUrl={pdfUrl}
                />{' '}
            </>
        );
    }
}
