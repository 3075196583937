import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';

import ExtraInfo from 'core/components/ExtraInfo.js';
import Icon from 'core/components/Icon.js';
import OpenIndicator from 'core/components/OpenIndicator.js';

export default class PracticeMoreInfo extends React.Component {
    static propTypes = {
        comment: PropTypes.string,
        customClass: PropTypes.string,
        openHours: PropTypes.arrayOf(
            PropTypes.shape({
                day: PropTypes.string,
                hours: PropTypes.string,
            }),
        ),
        openIndicator: PropTypes.string,
        paymentOptions: PropTypes.arrayOf(PropTypes.string),
        serviceOptions: PropTypes.string,
    };

    static defaultProps = {
        openHours: [],
        paymentOptions: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    @autobind
    toggleDropdown() {
        this.setState((state) => ({
            isOpen: !state.isOpen,
        }));
    }

    render() {
        const {
            comment,
            openHours,
            paymentOptions,
            serviceOptions,
        } = this.props;

        const {isOpen} = this.state;

        if (
            !comment &&
            !openHours.length &&
            !paymentOptions.length &&
            !serviceOptions
        ) {
            return null;
        }

        return (
            <>
                <button
                    aria-expanded={isOpen}
                    className="button-handle"
                    onClick={this.toggleDropdown}
                    type="button"
                >
                    <OpenIndicator isOpen={this.props.openIndicator}>
                        {'Hours and more'}
                    </OpenIndicator>
                    <OpenIndicator
                        displayIndicatorText={false}
                        isOpen={this.props.openIndicator}
                    >
                        {'More'}
                    </OpenIndicator>
                    <Icon name="dropDown" />
                </button>
                {isOpen && (
                    <ExtraInfo {...this.props} displayIndicator={false} />
                )}
            </>
        );
    }
}
